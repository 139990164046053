<template>
  <div class="title">部门管理</div>
  <el-card shadow="always">
    <div class="dept-header">
      <div class="dept-header__title">部门树配置</div>
      <!-- <div class="dept-header__btn" @click="confirmUpdate">确认修改</div> -->
    </div>

    <el-tree ref="treeDom" :data="data" class="dept-tree" node-key="id" default-expand-all :expand-on-click-node="false" style="padding: 20px 20px 0 20px;">
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <!-- <span v-if="!data.isDisabled">
            <el-input v-model="data.label" size="small" />
          </span> -->
          <span>{{ data.name }}</span>
          <span style="margin-left: 50px;">
            <a @click="edit(node, data)" style="vertical-align: middle;">
              <el-icon :size="20">
                <Edit />
              </el-icon>
            </a>
            <a @click="remove(node, data)" style="vertical-align: middle;margin-left:3px;">
              <el-icon :size="20">
                <Remove />
              </el-icon>
            </a>
            <a @click="append(data)" style="vertical-align: middle; margin-left:3px;">
              <el-icon :size="20">
                <CirclePlus />
              </el-icon>
            </a>
          </span>
        </span>
      </template>
    </el-tree>
    <el-icon :size="14" style="padding:0 5px 0 20px;vertical-align: -2px; color: blue;cursor:pointer;">
      <Plus />
    </el-icon>
    <span style="color: blue; cursor:pointer;" @click="appendFstNode">新增一级菜单</span>

  </el-card>

  <el-dialog title="重命名节点" v-model="editDialog" :width="300">
    <el-input v-model="treeLabel" type="text" maxlength="20"></el-input>
    <span v-show="showTip" style="color: red;">节点不能为空</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmNode">确 定</el-button>
      </span>
    </template>
  </el-dialog>

</template>  

<script>
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from 'vue';
import { CirclePlus, Remove, Edit, Plus } from "@element-plus/icons";
import { ElMessage } from 'element-plus';

export default {
  components: {
    CirclePlus,
    Remove,
    Edit,
    Plus
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      data: [
        // {
        //   id: 1,
        //   name: '一级 1',
        //   children: [
        //     {
        //       id: 4,
        //       name: '二级 1-1',
        //       children: [
        //         {
        //           id: 9,
        //           name: '三级 1-1-1'
        //         },
        //         {
        //           id: 10,
        //           name: '三级 1-1-2'
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   id: 2,
        //   name: '一级 2',
        //   children: [
        //     {
        //       id: 5,
        //       name: '二级 2-1'
        //     },
        //     {
        //       id: 6,
        //       name: '二级 2-2'
        //     },
        //   ],
        // },
        // {
        //   id: 3,
        //   name: '一级 3',
        //   children: [
        //     {
        //       id: 7,
        //       name: '二级 3-1'
        //     },
        //     {
        //       id: 8,
        //       name: '二级 3-2'
        //     },
        //   ],
        // },
      ],
      editDialog: false,
      treeLabel: '',
      currentNode: null,
      currentData: null,
      showTip: false
    })

    onMounted(() => {
      getTree()
    })

    const getTree = () => {
      proxy.$get('/cts/back/department/detail').then((res) => {
        if (res.code === '200') {
          state.data = res.data;
        }
      }).catch((err) => {

      });
    }

    const edit = (node, data) => {
      console.log(node, data)
      state.editDialog = true;
      state.treeLabel = data.name;
      state.currentNode = node;
      state.currentData = data;
    }

    const appendFstNode = () => {
      let params = {
        deptName: "新建一级节点",
        parentId: "0"
      }
      proxy.$post('/cts/back/department/add', params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          getTree()
        }
      }).catch((err) => {

      });
      // const newChild = { id: null, name: '新建一级节点', parentId: null, type: null, content: null, children: null }
      // state.data.push(newChild)
      // state.data = [...state.data]
    }

    const append = (data) => {
      let params = {
        deptName: "新建节点",
        parentId: data.id
      }
      proxy.$post('/cts/back/department/add', params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          getTree()
        }
      }).catch((err) => {

      });
      // const newChild = { id: null, name: '新建节点', parentId: '0', type: null, content: null, children: [] }
      // if (!data.children) {
      //   data.children = []
      // }
      // data.children.push(newChild)
      // state.data = [...state.data]
    }

    const remove = (node, data) => {
      if (data.children && data.children.length > 0) {
        ElMessage({
          message: '当前部门存在子部门，不能删除',
          type: 'warning',
        })
        return;
      }
      let params = {
        deptId: data.id
      }
      proxy.$post('/cts/back/department/delete', params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          getTree()
        }
      }).catch((err) => {

      });
      // const parent = node.parent
      // const children = parent.data.children || parent.data
      // const index = children.findIndex((d) => d.id === data.id)
      // children.splice(index, 1)
      // state.data = [...state.data]
    }

    const confirmNode = () => {
      state.showTip = false;
      const parent = state.currentNode.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === state.currentData.id);
      if (state.treeLabel === '') {
        state.showTip = true;
        return;
      }
      let params = {
        deptId: children[index].id,
        deptName: state.treeLabel
      }
      proxy.$post('/cts/back/department/modify', params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          getTree()
        }
      }).catch((err) => {

      });
      //children[index].name = state.treeLabel;
      state.editDialog = false
    }

    const confirmUpdate = () => {
      console.log(state.data)
      let params = state.data
      proxy.$post('/cts/back/department/modify', params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
        }
      }).catch((err) => {

      });
    }

    return {
      ...toRefs(state),
      append,
      remove,
      edit,
      confirmNode,
      confirmUpdate,
      appendFstNode
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.dept-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-weight: bold;
  }
  &__btn {
    background: rgb(56, 162, 138);
    width: 100px;
    text-align: center;
    padding: 5px 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
  }
}
</style>